<template>
  <div class="view-home">
    <div class="container">
      <div class="quiz-status my-4">
        <div class="card" v-show="countdown">
          <ul>
            <li class="time">
              {{ countdown }}
            </li>
            <li class="recording" v-if="quiz.proctoring">
              {{ $t('testing.recording') }}
            </li>
            <li class="interruption" v-if="quiz.proctoring">
              {{ $t('testing.interruption') }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container questions-wrap">
      <h2>
        Коммуникация
      </h2>
      <h3>
        Методика «Один за всех и все за одного!». Субтест № 2. «Забавные истории»
      </h3>
      <div class="collaboration-desc">
        Посмотри различные картинки с персонажем Батыр (тренер) и его учениками, коллегами, друзьями. Каждый сюжет
        основывается на первой картинке, изображающей действия персонажей в определенной ситуации. На трех других
        картинках изображены последующие действия персонажей. Выбери тот вариант ответа, с которым ты согласен. То есть,
        из трех картинок выбери ту, которая, по твоему мнению, верно изображает последующие действия персонажей.
      </div>

      <div class="d-flex flex-column">
        <div class="question-radio">
          1. <img src="/images/subtest2-funny-stories/Batyr1.png" width="100px" height="100px" alt=""/> Батыр собирает мячи в зале после игры.
          <div class="question-radio-list">
            <el-radio v-model="answersFunnyStories[0].choice" label="А">
              А) <img src="/images/subtest2-funny-stories/Batyr1-1.png" width="100px" height="100px" alt=""/> Батыр собирает мячи один
            </el-radio>
            <el-radio v-model="answersFunnyStories[0].choice" label="Б">
              Б) <img src="/images/subtest2-funny-stories/Batyr1-2.png" width="100px" height="100px" alt=""/> Батыр собирает мячи вместе с игроками
            </el-radio>
            <el-radio v-model="answersFunnyStories[0].choice" label="В">
              В) <img src="/images/subtest2-funny-stories/Batyr1-3.png" width="100px" height="100px" alt=""/> Батыр говорит игрокам собирать мячи самим.
            </el-radio>
          </div>
        </div>
        <div class="question-radio">
          2. <img src="/images/subtest2-funny-stories/Batyr2.png" width="100px" height="100px" alt=""/> Батыр и его друг во время бега увидели кошку, которая не могла слезть с дерева
          <div class="question-radio-list">
            <el-radio v-model="answersFunnyStories[1].choice" label="А">
              А) <img src="/images/subtest2-funny-stories/Batyr2-1.png" width="100px" height="100px" alt=""/> Батыр идет на помощь один
            </el-radio>
            <el-radio v-model="answersFunnyStories[1].choice" label="Б">
              Б) <img src="/images/subtest2-funny-stories/Batyr2-2.png" width="100px" height="100px" alt=""/> Батыр вызывает спасателей.
            </el-radio>
            <el-radio v-model="answersFunnyStories[1].choice" label="В">
              В) <img src="/images/subtest2-funny-stories/Batyr2-3.png" width="100px" height="100px" alt=""/> Батыр помогает кошке вместе с другом.
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          3. <img src="/images/subtest2-funny-stories/Batyr3.png" width="100px" height="100px" alt=""/> Батыр видит ученика, который один тренируется в спортзале
          <div class="question-radio-list">
            <el-radio v-model="answersFunnyStories[2].choice" label="А">
              А) <img src="/images/subtest2-funny-stories/Batyr3-1.png" width="100px" height="100px" alt=""/> Батыр проходит мимо, не обращая внимания.
            </el-radio>
            <el-radio v-model="answersFunnyStories[2].choice" label="Б">
              Б) <img src="/images/subtest2-funny-stories/Batyr3-2.png" width="100px" height="100px" alt=""/> Батыр просит ученика освободить зал.
            </el-radio>
            <el-radio v-model="answersFunnyStories[2].choice" label="В">
              В) <img src="/images/subtest2-funny-stories/Batyr3-3.png" width="100px" height="100px" alt=""/> Батыр просит ученика освободить зал.
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          4. <img src="/images/subtest2-funny-stories/Batyr4.png" width="100px" height="100px" alt=""/> Коллега Батыра переезжает
          <div class="question-radio-list">
            <el-radio v-model="answersFunnyStories[3].choice" label="А">
              А) <img src="/images/subtest2-funny-stories/Batyr4-1.png" width="100px" height="100px" alt=""/> Батыр помогает коллеге
            </el-radio>
            <el-radio v-model="answersFunnyStories[3].choice" label="Б">
              Б) <img src="/images/subtest2-funny-stories/Batyr4-2.png" width="100px" height="100px" alt=""/> Батыр зовет свою команду на помощь коллеге
            </el-radio>
            <el-radio v-model="answersFunnyStories[3].choice" label="В">
              В) <img src="/images/subtest2-funny-stories/Batyr4-3.png" width="100px" height="100px" alt=""/> Батыр зовет свою команду на помощь и помогает вместе
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          5. <img src="/images/subtest2-funny-stories/Batyr5.png" width="100px" height="100px" alt=""/> Коллеги приглашают Батыра вместе пообедать
          <div class="question-radio-list">
            <el-radio v-model="answersFunnyStories[4].choice" label="А">
              А) <img src="/images/subtest2-funny-stories/Batyr5-1.png" width="100px" height="100px" alt=""/> Батыр не согласился
            </el-radio>
            <el-radio v-model="answersFunnyStories[4].choice" label="Б">
              Б) <img src="/images/subtest2-funny-stories/Batyr5-2.png" width="100px" height="100px" alt=""/> Батыр предпочитал сидеть и есть в одиночестве
            </el-radio>
            <el-radio v-model="answersFunnyStories[4].choice" label="В">
              В) <img src="/images/subtest2-funny-stories/Batyr5-3.png" width="100px" height="100px" alt=""/> Батыр обедает с коллегами
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          6. <img src="/images/subtest2-funny-stories/Batyr6.png" width="100px" height="100px" alt=""/> Батыр гуляет по парку и видит, как его игроки играют в волейбол
          <div class="question-radio-list">
            <el-radio v-model="answersFunnyStories[5].choice" label="А">
              А) <img src="/images/subtest2-funny-stories/Batyr6-1.png" width="100px" height="100px" alt=""/> Батыр идет и играет с ними
            </el-radio>
            <el-radio v-model="answersFunnyStories[5].choice" label="Б">
              Б) <img src="/images/subtest2-funny-stories/Batyr6-2.png" width="100px" height="100px" alt=""/> Батыр предлагает им прогуляться вместе
            </el-radio>
            <el-radio v-model="answersFunnyStories[5].choice" label="В">
              В) <img src="/images/subtest2-funny-stories/Batyr6-3.png" width="100px" height="100px" alt=""/> Батыр продолжает один гулять
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          7. <img src="/images/subtest2-funny-stories/Batyr7.png" width="100px" height="100px" alt=""/> Батыр идет по улице с другом и видит бабушку с большой сумкой
          <div class="question-radio-list">
            <el-radio v-model="answersFunnyStories[6].choice" label="А">
              А) <img src="/images/subtest2-funny-stories/Batyr7-1.png" width="100px" height="100px" alt=""/> Батыр проходит мимо
            </el-radio>
            <el-radio v-model="answersFunnyStories[6].choice" label="Б">
              Б) <img src="/images/subtest2-funny-stories/Batyr7-2.png" width="100px" height="100px" alt=""/> Его друг помог бабушке, а Батыр смотрел
            </el-radio>
            <el-radio v-model="answersFunnyStories[6].choice" label="В">
              В) <img src="/images/subtest2-funny-stories/Batyr7-3.png" width="100px" height="100px" alt=""/> Батыр с другом помогают бабушке
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          8. <img src="/images/subtest2-funny-stories/Batyr8.png" width="100px" height="100px" alt=""/> Батыр видит, как люди собирают мусор на улице
          <div class="question-radio-list">
            <el-radio v-model="answersFunnyStories[7].choice" label="А">
              А) <img src="/images/subtest2-funny-stories/Batyr8-1.png" width="100px" height="100px" alt=""/> Батыр проходит мимо
            </el-radio>
            <el-radio v-model="answersFunnyStories[7].choice" label="Б">
              Б) <img src="/images/subtest2-funny-stories/Batyr8-2.png" width="100px" height="100px" alt=""/> Батыр собирает мусор один
            </el-radio>
            <el-radio v-model="answersFunnyStories[7].choice" label="В">
              В) <img src="/images/subtest2-funny-stories/Batyr8-3.png" width="100px" height="100px" alt=""/> Батыр собирает мусор вместе с командой
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          9. <img src="/images/subtest2-funny-stories/Batyr9.png" width="100px" height="100px" alt=""/> Батыр видит, как его команда теряет очки и проигрывает в игре
          <div class="question-radio-list">
            <el-radio v-model="answersFunnyStories[8].choice" label="А">
              А) <img src="/images/subtest2-funny-stories/Batyr9-1.png" width="100px" height="100px" alt=""/> Батыр вместе с командой разрабатывает тактику
            </el-radio>
            <el-radio v-model="answersFunnyStories[8].choice" label="Б">
              Б) <img src="/images/subtest2-funny-stories/Batyr9-2.png" width="100px" height="100px" alt=""/> Батыр ругает команду
            </el-radio>
            <el-radio v-model="answersFunnyStories[8].choice" label="В">
              В) <img src="/images/subtest2-funny-stories/Batyr9-3.png" width="100px" height="100px" alt=""/> Батыр зовет на помощь второго тренера и вместе решает проблему
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          10. <img src="/images/subtest2-funny-stories/Batyr10.png" width="100px" height="100px" alt=""/> Команда разбивает окно школы мячом во время игры
          <div class="question-radio-list">
            <el-radio v-model="answersFunnyStories[9].choice" label="А">
              А) <img src="/images/subtest2-funny-stories/Batyr10-1.png" width="100px" height="100px" alt=""/> Батыр ругает учеников
            </el-radio>
            <el-radio v-model="answersFunnyStories[9].choice" label="Б">
              Б) <img src="/images/subtest2-funny-stories/Batyr10-2.png" width="100px" height="100px" alt=""/> Батыр зовет человека, чтобы забрать разбитое стекло
            </el-radio>
            <el-radio v-model="answersFunnyStories[9].choice" label="В">
              В) <img src="/images/subtest2-funny-stories/Batyr10-3.png" width="100px" height="100px" alt=""/> Батыр берет в руки веник и собирает осколки вместе с учениками, которые разбили окно
            </el-radio>
          </div>
        </div>
      </div>

      <!--      <button @click="sendCollaborationResults">Отправить</button>-->
      <el-button @click="openModal"
                 class="btn btn-danger">{{ $t('testing.finish-modal-button') }}
      </el-button>

    </div>
    <b-modal id="exit-modal" hide-footer hide-header>
      <div id="modal-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('exit-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <img class="modal-warning-img mb-3" src="/images/profile/modal-warning.svg">
          <h3 class="mb-0">{{modalTitle}}</h3>
          <p>{{ $t('testing.finish-modal-desc') }}</p>
          <div class="clearfix pt-3">
            <el-button @click="sendIncorrectPhraseResults(false)" class="btn btn-primary mx-2">
              {{ $t('testing.finish-modal-button') }}
            </el-button>
            <a @click="$bvModal.hide('exit-modal')"
               class="btn btn-outline-primary mx-2">{{ $t('testing.finish-modal-cancel') }}</a>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import 'katex/dist/katex.min.css';
import Loader from '../../components/Loader';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue';

Vue.use(VueViewer)
export default {
  name: "Index",
  data() {
    return {
      modalTitle: this.$t('testing.finish-modal-title'),
      timerInterval: null,
      countdown: 0,
      quiz: '',
      answersFunnyStories: [
        { choice: "", question: "1" },
        { choice: "", question: "2" },
        { choice: "", question: "3" },
        { choice: "", question: "4" },
        { choice: "", question: "5" },
        { choice: "", question: "6" },
        { choice: "", question: "7" },
        { choice: "", question: "8" },
        { choice: "", question: "9" },
        { choice: "", question: "10" }
      ],
      score: null,
      correctAnswers: ["В", "В", "В", "Б", "Б", "В", "Б", "А", "В", "В"],
      interpretationResult: {
        high: 'Высокий уровень коммуникативных способностей и коллаборации. У тебя хорошие коммуникативные способности. Ты  способен легко и быстро устанавливать контакт с окружающими людьми, а также умеешь сотрудичать со сверстниками для решения общих задач.  Продолжай развивать свои способности, активно участвуя в общественных мероприятиях класса и школы.',
        middle: 'Средний уровень коммуникативных способностей и коллаборации. У тебя хорошие коммуникативные способности. Однако, ты можешь достигнуть большего, потому что умеешь получать обратную связь и преодолевать коммуникативные барьеры. Ты испытываешь незначительные трудности при установлении контактов с другими людьми, поэтому рекомендуем тебе развивать свои коммуникативные навыки на психологических занятиях.',
        low: 'Ниже среднего (коммуникативные способности в зоне ближайшего развития). Пока ты затрудняешься проявлять свои коммуникативные умения, способности, вероятно потому, что не всегда уверен в себе. Иногда ты испытываешь трудности в общении с другими людьми.  Поэтому рекомендуем тебе развивать свои навыки на психологических занятиях.',
      }
    }
  },
  methods: {
    interpretScore(score) {
      if (score >= 18) {
        return this.interpretationResult.high;
      } else if (score >= 10) {
        return this.interpretationResult.middle;
      } else {
        return this.interpretationResult.low;
      }
    },
    validateAnswers() {
      return this.answersFunnyStories.some(
          (answer) => answer.choice.trim() === ""
      );
    },
    openModal() {
      this.$bvModal.show('exit-modal');
    },
    async sendIncorrectPhraseResults(timer) {

      if (!timer && this.validateAnswers()) {

        this.$bvModal.hide('exit-modal');

        return Vue.toastr({
          message: this.$t('error-title'),
          description: 'Ответьте на все вопросы',
          type: 'error'
        })
      }

      this.score = this.correctAnswers.reduce((total, correct, index) => {
        return total + (this.answersFunnyStories[index].choice === correct ? 1 : 0);
      }, 0);

      let params = JSON.parse(localStorage.getItem('quiz-params-15-17'))
      const incorrectPhraseScore = params.answer_id.incorrectPhraseData.score
      const totalScore = incorrectPhraseScore + this.score

      const funnyStoriesData = {
        score: this.score,
        answers: this.answersFunnyStories,
        interpret: this.interpretScore(totalScore),
        addInfo : {
          totalScore,
        }
      }

      params.answer_id = {...params.answer_id, funnyStoriesData}

      localStorage.setItem('quiz-params-15-17', JSON.stringify(params))
      const quizToken = localStorage.getItem('quiz-token')

      const response = await this.$http.post(API_ROOT + '/api/user-answer/' + quizToken, params)

      if (response.body.status == 'success') {

        localStorage.setItem('current-test', 'thomas-questionnaire')

        Vue.toastr({
          message: this.$t('successful'),
          description: this.$t('saved-successfully'),
          type: 'success'
        })
      } else {
        Vue.toastr({
          message: this.$t('error-title'),
          description: this.$t('error-saving'),
          type: 'error'
        })
      }
      console.log('params', params)

      setTimeout(() => {
        this.$router.push({ path: '/about-new/collaboration-2' })
      }, 1500)

    },
    startTimer(duration) {
      let timer = duration, minutes, seconds;
      this.timerInterval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.countdown = minutes + ":" + seconds;

        let savedTimer = (parseInt(minutes) * 60) + parseInt(seconds);
        localStorage.setItem('timerFunnyStories', savedTimer);

        if (--timer < 0) {
          this.sendIncorrectPhraseResults(true)
          clearInterval(this.timerInterval);
        }
      }, 1000);
    },
  },
  mounted() {
    if (localStorage.getItem('timerFunnyStories')) {
      this.startTimer(localStorage.getItem('timerFunnyStories'))
    } else {
      this.startTimer(300)
    }

    if (localStorage.getItem('current-test')) {
      this.$router.push({name: localStorage.getItem('current-test')});
    }

    const testLang = localStorage.getItem('test_lang');
    if (testLang) this.$i18n.locale = testLang;
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  }
}
</script>

<style>
.select-custom-collaboration {
  width: 240px !important;
}

.collaboration-desc {
  margin: 20px 0;
}

.collaboration-options {
  cursor: pointer;
  position: relative;
}

.el-select-group__title {
  position: relative;
}

.el-select-group__title::after {
  position: absolute;
  right: 10px;
  top: 2px;
  content: '▼';
}


</style>
